<template>
  <!-- LINK TO CODE:
  https://codepen.io/stenvdb/pen/wvBoYQO
  -->
  <div id="app">
    <div
      class="
        mt-8
        relative
        block
        w-full
        border-2 border-gray-300 border-dashed
        rounded-lg
        p-12
        text-center
        hover:border-gray-400
        focus:outline-none
        focus:ring-2
        focus:ring-offset-2
        focus:ring-indigo-500
      "
      @dragover="dragover"
      @dragleave="dragleave"
      @drop="drop"
    >
      <input
        :disabled="isDisabled"
        type="file"
        multiple
        name="fields[assetsFieldHandle][]"
        id="assetsFieldHandle"
        class="w-px h-px opacity-0 overflow-hidden absolute"
        @change="onChange"
        ref="file"
        accept=".pdf,.jpg,.jpeg,.png"
      />

      <label for="assetsFieldHandle" class="block cursor-pointer">
        <div v-if="!isDisabled">
          Drag and drop files or
          <span class="underline">click here</span> to upload files
        </div>
        <div v-if="isDisabled">
          You have reached your image capacity limit! Delete images to upload others.
        </div>
      </label>
      <ul class="mt-4" v-if="this.filelist.length" v-cloak style="list-style-type: none;">
        <li class="text-sm p-1" v-for="file in filelist" :key="file">
          {{ file.name
          }}
          <!-- <button
            class="ml-2"
            type="button"
            @click="remove(filelist.indexOf(file))"
            title="Remove file"
          >
            X
          </button> -->
        </li>
      </ul>
    </div>
  </div>
</template>
<script>
import { UploadcareService } from "../services/uploadcareService";
export default {
  el: "#app",
  delimiters: ["${", "}"], // Avoid Twig conflicts
  props:{
    isDisabled: Boolean,
    maxNumUploads: Number
  },
  data() {
    return {
      filelist: [], // Store our uploaded files
      fileUUIDs: [],
    };
  },
  methods: {
    onChange() {
      const ONE_MB = 1048576;
      let validFiles = [];
      if (!this.isDisabled) {
        this.filelist = [...this.$refs.file.files];
        // this.testUploadcareServiceUPLOAD(this.filelist[0]);
        for (let i = 0; i < this.filelist.length; i++) {
          if (this.filelist[i].size / ONE_MB <= process.env.VUE_APP_MAX_OTHER_SIZE_MB) {
            if (validFiles.length < this.maxNumUploads) {
              validFiles.push(this.filelist[i]);
            }
            else {
              //" - file not uploaded; reached max number of uploads"
              let tempImage = {name: this.filelist[i].name + " - file not uploaded; reached max number of uploads"};
              this.filelist[i] = tempImage;
            }
          }
          else {
            this.filelist[i].name += " - file larger than 1mb; cannot upload";
          }
        }
      }
      this.$emit("media_upload", validFiles);
    },
    remove(i) {
      this.filelist.splice(i, 1);
      this.testUploadcareServiceDELETE(i);
    },
    dragover(event) {
      event.preventDefault();
      // Add some visual fluff to show the user can drop its files
      if (!event.currentTarget.classList.contains("bg-green-300")) {
        event.currentTarget.classList.remove("bg-gray-100");
        event.currentTarget.classList.add("bg-green-300");
      }
    },
    dragleave(event) {
      // Clean up
      event.currentTarget.classList.add("bg-gray-100");
      event.currentTarget.classList.remove("bg-green-300");
    },
    drop(event) {
      event.preventDefault();
      this.$refs.file.files = event.dataTransfer.files;
      this.onChange(); // Trigger the onChange event manually
      // Clean up
      event.currentTarget.classList.add("bg-gray-100");
      event.currentTarget.classList.remove("bg-green-300");
    },
    async testUploadcareServiceUPLOAD(file) {
      let respUpload = await UploadcareService.uploadImage(
        "username",
        file.name,
        file
      );
      this.fileUUIDs.push(respUpload.data[file.name]);
      await UploadcareService.getImageData(
        respUpload.data[file.name]
      );
    },
    async testUploadcareServiceDELETE(index) {
      let uuid = this.fileUUIDs[index];
      this.fileUUIDs.splice(index, 1);
      await UploadcareService.deleteImage("username", uuid);
    },
  },
};
</script>
<style>
[v-cloak] {
  display: none;
}
</style>
