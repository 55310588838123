<template>
  <div v-if="sectionData">
    <Disclosure
      as="div"
      class="relative bg-indigo-600 pb-32 overflow-hidden"
      v-slot="{ open }"
    >
      <div
        :class="[
          open ? 'bottom-0' : 'inset-y-0',
          'absolute flex justify-center inset-x-0 left-1/2 transform -translate-x-1/2 w-full overflow-hidden lg:inset-y-0',
        ]"
        aria-hidden="true"
      >
        <div class="flex-grow bg-indigo-600 bg-opacity-75" />
        <svg
          class="flex-shrink-0"
          width="1750"
          height="308"
          viewBox="0 0 1750 308"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            opacity=".1"
            d="M1465.84 308L16.816 0H1750v308h-284.16z"
            fill="#fff"
          />
          <path
            opacity=".05"
            d="M1733.19 0L284.161 308H0V0h1733.19z"
            fill="#fff"
          />
        </svg>
        <div class="flex-grow bg-indigo-600 bg-opacity-75" />
      </div>
      <header class="relative py-10">
        <div class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <h1 class="text-3xl font-bold text-white">Settings</h1>
        </div>
      </header>
    </Disclosure>

    <main class="relative -mt-32">
      <div class="max-w-screen-xl mx-auto pb-6 px-4 sm:px-6 lg:pb-16 lg:px-8">
        <div class="bg-white rounded-lg shadow overflow-hidden">
          <div
            class="
              divide-y divide-gray-200
              lg:grid lg:grid-cols-12 lg:divide-y-0 lg:divide-x
            "
          >
            <aside class="py-6 lg:col-span-3">
              <nav class="space-y-1">
                <a
                  v-for="item in subNavigation"
                  :key="item.name"
                  :href="item.href"
                  :class="[
                    item.current
                      ? 'bg-teal-50 border-teal-500 text-teal-700 hover:bg-teal-50 hover:text-teal-700'
                      : 'border-transparent text-gray-900 hover:bg-gray-50 hover:text-gray-900',
                    'group border-l-4 px-3 py-2 flex items-center text-sm font-medium',
                  ]"
                  :aria-current="item.current ? 'page' : undefined"
                >
                  <component
                    :is="item.icon"
                    :class="[
                      item.current
                        ? 'text-teal-500 group-hover:text-teal-500'
                        : 'text-gray-400 group-hover:text-gray-500',
                      'flex-shrink-0 -ml-1 mr-3 h-6 w-6',
                    ]"
                    aria-hidden="true"
                  />
                  <span class="truncate">
                    {{ item.name }}
                  </span>
                </a>
              </nav>
            </aside>

            <div
              class="divide-y divide-gray-200 lg:col-span-9"
              action="#"
              method="POST"
            >
              <div class="py-6 px-4 sm:p-6 lg:pb-8">
                <div>
                  <h2 class="text-lg leading-6 font-medium text-gray-900">
                    Photos
                  </h2>
                  <p class="mt-1 text-sm text-gray-500">
                    These are the images that will appear on your public profile.
                  </p>
                </div>
                
                <div v-if="!isLoading && uploadedMediaFiles">
                  <MediaUploader :isDisabled="!(userData.MaxNumberOfImages > uploadedMediaFiles.length)" :maxNumUploads="(userData.MaxNumberOfImages - uploadedMediaFiles.length)" v-on:media_upload="onMediaUpload"/>
                </div>

                <!-- Gallery -->
                <LoadingSpinner v-if="isLoading"/>
                <section v-if="!isLoading" class="mt-8 pb-16" aria-labelledby="gallery-heading">
                  <!-- Success message -->
                  <div v-if="showSuccess" class="rounded-md bg-green-50 p-4 mb-8">
                    <div class="flex">
                      <div class="flex-shrink-0">
                        <!-- Heroicon name: solid/check-circle -->
                        <svg class="h-5 w-5 text-green-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                          <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clip-rule="evenodd" />
                        </svg>
                      </div>
                      <div class="ml-3">
                        <h3 class="text-sm font-medium text-green-800">
                          Upload Successful
                        </h3>
                      </div>
                    </div>
                  </div>
                  <!-- Failure message -->
                  <div v-if="showFailure" class="rounded-md bg-red-50 p-4 mb-8">
                    <div class="flex">
                      <div class="flex-shrink-0">
                        <!-- Heroicon name: solid/x-circle -->
                        <svg
                          class="h-5 w-5 text-red-400"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                          aria-hidden="true"
                        >
                          <path
                            fill-rule="evenodd"
                            d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z"
                            clip-rule="evenodd"
                          />
                        </svg>
                      </div>
                      <div class="ml-3">
                        <h3 class="text-sm font-medium text-red-800">Upload Failed</h3>
                        <div class="mt-2 text-red-700">
                          <p class=" text-red-700 text-sm ">Lorem ipsum dolor sit amet consectetur adipisicing elit. Aliquid pariatur, ipsum similique veniam.</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- failed to delete image message-->
                  <div v-if="showFailureToDelete" class="rounded-md bg-red-50 p-4 mb-8">
                    <div class="flex">
                      <div class="flex-shrink-0">
                        <!-- Heroicon name: solid/x-circle -->
                        <svg
                          class="h-5 w-5 text-red-400"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                          aria-hidden="true"
                        >
                          <path
                            fill-rule="evenodd"
                            d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z"
                            clip-rule="evenodd"
                          />
                        </svg>
                      </div>
                      <div class="ml-3">
                        <h3 class="text-sm font-medium text-red-800">Upload Failed</h3>
                        <div class="mt-2 text-red-700">
                          <p class=" text-red-700 text-sm ">Lorem ipsum dolor sit amet consectetur adipisicing elit. Aliquid pariatur, ipsum similique veniam.</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <h2 id="gallery-heading" class="sr-only">Your images</h2>
                  <ul
                    role="list"
                    class="
                      grid
                      list-none
                      mx-auto
                      grid-cols-2
                      gap-x-4 gap-y-8
                      sm:grid-cols-3 sm:gap-x-6
                      md:grid-cols-4
                      lg:grid-cols-3
                      xl:grid-cols-4 xl:gap-x-8
                    "
                  >
                    <li v-for="file in uploadedMediaFiles" :key="file.data.original_filename" class="relative">
                      <div
                        :class="[
                          file.current
                            ? 'ring-2 ring-offset-2 ring-indigo-500 group'
                            : 'focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-offset-gray-100 focus-within:ring-indigo-500',
                          'group block w-full aspect-w-10 aspect-h-7 rounded-lg bg-gray-100 overflow-hidden group',
                        ]"
                        @mouseover="upHere = true" @mouseleave="upHere = false" 
                      >
                        <img class="object-cover"
                          :src="getUploadcareImage(file.data.uuid)"
                          alt=""
                        />
                        <!-- :class="[
                            file.current ? '' : 'group-hover:opacity-75',
                            'object-cover pointer-events-none',
                          ]" -->
                          <span class="sr-only"
                            >View details for {{ file.data.original_filename }}</span
                          >
                          <div class="flex items-center content-center place-content-center opacity-0 group-hover:opacity-100 transition-all">
                            <button v-on:click="open = true"  type="button" class="inline-flex items-center p-1 border hidden border-transparent rounded-full shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                              <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z" />
                              </svg>
                            </button>
                            <button type="button" class="ml-2 inline-flex items-center p-1 border border-transparent rounded-full shadow-sm text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                            v-on:click="removeImage(file.data.uuid)">
                              <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
                              </svg>
                            </button>
                          </div>
                      </div>
                      <p
                        class="
                          mt-2
                          block
                          text-sm
                          font-medium
                          text-gray-900
                          truncate
                          pointer-events-none
                        "
                      >
                        {{ file.data.original_filename }}
                      </p>
                      <p
                        class="
                          block
                          text-sm
                          font-medium
                          text-gray-500
                          pointer-events-none
                        "
                      >
                        {{ Math.round(file.data.size / 1024) }}kb
                      </p>
                    </li>
                  </ul>
                </section>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  </div>

  <TransitionRoot as="template" :show="open">
    <Dialog as="div" class="fixed z-10 inset-0 overflow-y-auto" @close="open = false">
      <div class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
        <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0" enter-to="opacity-100" leave="ease-in duration-200" leave-from="opacity-100" leave-to="opacity-0">
          <DialogOverlay class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </TransitionChild>

        <!-- This element is to trick the browser into centering the modal contents. -->
        <span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
        <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95" enter-to="opacity-100 translate-y-0 sm:scale-100" leave="ease-in duration-200" leave-from="opacity-100 translate-y-0 sm:scale-100" leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
          <div class="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
            <div>
              <div class="text-left">
                <div class="mt-2">
                <div class="mb-2">
                <label for="email" class="block text-sm font-medium text-gray-700">Title</label>
                <div class="mt-1">
                  <input type="text" value="Image Title" class="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md" placeholder="" />
                </div>
                </div>
                <div class="mb-2">
                <label for="email" class="block text-sm font-medium text-gray-700">Alt text</label>
                <div class="mt-1">
                  <input type="text" value="Lorem ipsum dolor sit amet." class="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md" placeholder="" />
                </div>
              </div>
                </div>
              </div>
            </div>
            <div class="mt-5 sm:mt-6 sm:grid sm:grid-cols-2 sm:gap-3 sm:grid-flow-row-dense">
              <button type="button" class="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-indigo-500 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:col-start-2 sm:text-sm" @click="open = false">
                Save
              </button>
              <button type="button" class="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:col-start-1 sm:text-sm" @click="open = false" ref="cancelButtonRef">
                Cancel
              </button>
            </div>
          </div>
        </TransitionChild>
      </div>
    </Dialog>
  </TransitionRoot>
<div v-if="!isPremium" class="fixed bottom-0 inset-x-0 pb-2 sm:pb-5  hidden">
    <div class="max-w-7xl mx-auto px-2 sm:px-6 lg:px-8">
      <div class="p-2 rounded-lg bg-yellow-400 shadow-lg sm:p-3">
        <div class="flex items-center justify-between flex-wrap">
          <div class="w-0 flex-1 flex items-center">
            <span class="flex p-2 rounded-lg bg-yellow-600">
              <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 text-white" viewBox="0 0 20 20" fill="currentColor">
                <path vector-effect="non-scaling-stroke" fill-rule="evenodd" d="M8.257 3.099c.765-1.36 2.722-1.36 3.486 0l5.58 9.92c.75 1.334-.213 2.98-1.742 2.98H4.42c-1.53 0-2.493-1.646-1.743-2.98l5.58-9.92zM11 13a1 1 0 11-2 0 1 1 0 012 0zm-1-8a1 1 0 00-1 1v3a1 1 0 002 0V6a1 1 0 00-1-1z" clip-rule="evenodd" />
              </svg>
            </span>
            <p class="ml-3 font-medium text-white truncate">
              <span class="md:hidden text-black">
                Unlock additional features with a paid membership!
              </span>
              <span class="hidden md:inline text-black">
                 Unlock additional features with a paid membership!
              </span>
            </p>
          </div>
          <div class="order-3 mt-2 flex-shrink-0 w-full sm:order-2 sm:mt-0 sm:w-auto">
            <a href="/Billing" class="flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-black-600 bg-white hover:bg-yellow-50">
              See Pricing Options
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
import { ref } from "vue";
import {
  Disclosure,
  DisclosureButton,
  DisclosurePanel,
  Menu,
  MenuButton,
  MenuItem,
  MenuItems,
  Switch,
  SwitchDescription,
  SwitchGroup,
  SwitchLabel,
  Dialog, 
  DialogOverlay, 
  DialogTitle, 
  TransitionChild, 
  TransitionRoot
} from "@headlessui/vue";
import { SearchIcon } from "@heroicons/vue/solid";
import {
  BellIcon,
  CreditCardIcon,
  KeyIcon,
  MenuIcon,
  UserCircleIcon,
  VideoCameraIcon,
  PhotographIcon,
  XIcon,
} from "@heroicons/vue/outline";
import FileUploader from "../components/FileUploader.vue";
import MediaUploader from "../components/MediaUploader.vue";
import LoadingSpinner from "../components/LoadingSpinner.vue"
import { OcContentService } from "../services/ocContentService";
import { NopService } from "../services/nopService";
import { UploadcareService } from "../services/uploadcareService"
import { CookieService } from '../services/cookieService';

const user = {
  name: "John Cooper",
  handle: "johncooper",
  email: "johncooper@example.com",
  imageUrl:
    "https://images.unsplash.com/photo-1463453091185-61582044d556?ixlib=rb-=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=8&w=1024&h=1024&q=80",
};
const navigation = [
  { name: "Dashboard", href: "#", current: true },
  { name: "Jobs", href: "#", current: false },
  { name: "Applicants", href: "#", current: false },
  { name: "Company", href: "#", current: false },
];
const subNavigation = [
  { name: "Profile", href: "/Dashboard", icon: UserCircleIcon, current: false },
  { name: "Media", href: "/Media", icon: PhotographIcon, current: true },
  { name: "Videos", href: "/Videos", icon: VideoCameraIcon, current: false },
  { name: "Password", href: "/Password", icon: KeyIcon, current: false },
  // { name: "Membership", href: "/Billing", icon: CreditCardIcon, current: false },
];
const userNavigation = [
  { name: "Your Profile", href: "#" },
  { name: "Settings", href: "#" },
  { name: "Sign out", href: "#" },
];
const tabs = [{ name: "Recently Added", href: "#", current: true }];
const files = [
  {
    name: "Image Title",
    size: "IMG_4985.jpg",
    source:
      "https://images.unsplash.com/photo-1582053433976-25c00369fc93?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=512&q=80",
    current: false,
  },
  {
    name: "Image Title",
    size: "IMG_4985.jpg",
    source:
      "https://images.unsplash.com/photo-1582053433976-25c00369fc93?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=512&q=80",
    current: false,
  },
  {
    name: "Image Title",
    size: "IMG_4985.jpg",
    source:
      "https://images.unsplash.com/photo-1582053433976-25c00369fc93?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=512&q=80",
    current: false,
  },
  {
    name: "Image Title",
    size: "IMG_4985.jpg",
    source:
      "https://images.unsplash.com/photo-1582053433976-25c00369fc93?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=512&q=80",
    current: false,
  },
  // More files...
];
const currentFile = {
  name: "IMG_4985.HEIC",
  size: "3.9 MB",
  source:
    "https://images.unsplash.com/photo-1582053433976-25c00369fc93?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=512&q=80",
  information: {
    "Uploaded by": "Marie Culver",
    Created: "June 8, 2020",
    "Last modified": "June 8, 2020",
    Dimensions: "4032 x 3024",
    Resolution: "72 x 72",
  },
  sharedWith: [
    {
      id: 1,
      name: "Aimee Douglas",
      imageUrl:
        "https://images.unsplash.com/photo-1502685104226-ee32379fefbe?ixlib=rb-=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=3&w=1024&h=1024&q=80",
    },
    {
      id: 2,
      name: "Andrea McMillan",
      imageUrl:
        "https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixqx=oilqXxSqey&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80",
    },
  ],
};
export default {
  components: {
    Disclosure,
    DisclosureButton,
    DisclosurePanel,
    Menu,
    MenuButton,
    MenuItem,
    MenuItems,
    Switch,
    SwitchDescription,
    SwitchGroup,
    SwitchLabel,
    BellIcon,
    MenuIcon,
    SearchIcon,
    XIcon,
    FileUploader,
    MediaUploader,
    LoadingSpinner,
    Dialog,
    DialogOverlay,
    DialogTitle,
    TransitionChild,
    TransitionRoot,
  },
  setup() {
    const availableToHire = ref(true);
    const privateAccount = ref(false);
    const allowCommenting = ref(true);
    const allowMentions = ref(true);
    const open = ref(false)

    return {
      user,
      navigation,
      subNavigation,
      userNavigation,
      availableToHire,
      privateAccount,
      allowCommenting,
      allowMentions,
      tabs,
      files,
      currentFile,
      open,
    };
  },
  data() {
    return {
      sectionData: null,
      userData: null,
      uploadedMediaFiles: null,
      isLoading: true,
      isPremium: null,
      showSuccess: false,
      showFailure: false,
      showFailureToDelete: false,
    };
  },
  created() {
    this.isLoading = true;
    this.fetchOcData();
    this.fetchUserData();
    this.isLoading = false;
  },
  methods: {
    async fetchOcData() {
      this.sectionData = await OcContentService.fetchPageData(
        "Settings - Media",
        "KrickersSectionContent"
      );
    },
    async fetchUserData() {
      let id = CookieService.getLoginCookie()
      if (id) {
        this.userData = await NopService.GetCustomerDetailsById(id);
        this.isPremium = this.userData.IsPremium;
        await this.getNopImages();
      }
      else {
        this.emitter.emit("logged-in", false);
      }
    },
    async onMediaUpload(fileList) {
      this.isLoading = true;
      this.showSuccess = false;
      this.showFailure = false;
      let newUuids = [];
      //1. go through each newly uploaded file
      for (var file of fileList) {
        //2. for each new file (stuff in fileList), make sure it is not already there
        if (this.uploadedMediaFiles.filter(image => image.data.original_filename == file.name).length == 0) {
          //3. it's not there, upload to CDN
          let resp = await UploadcareService.uploadImage(file.name, file);
          if ((typeof resp) === "object") {
            //4. a failure in uploadcare returns a plain/text reponse instead of a json,
            //so only update user data to include new UUID for the file if resp is a json
            this.userData.OtherImagesUUID = this.userData.OtherImagesUUID + resp.data[file.name] + "," //this will get the uuid
            newUuids.push(resp.data[file.name]) //save in case we need to remove them
          }
        }
      }

      //5. update user data in db
      this.userData["IsActive"] = 1;//for some reason, it defaults to zero so this is mandatory every time an update happens >__>
      //this.userData = await NopService.UpdateCustomer(this.userData);
      let resp = await NopService.UpdateCustomer(this.userData);
      if (resp.Status === 1) {
        this.showSuccess = true;
        this.userData = resp.Data;
      }
      else {
        this.showFailure = true;
        for (var i = 0; i < newUuids.length; i++) {
          this.userData.OtherImagesUUID = this.userData.OtherImagesUUID.replace(newUuids[i] + ",", "")
          await UploadcareService.deleteImage(newUuids[i])
        }
      }

      //6. refresh the file list
      await this.getNopImages();
      this.isLoading = false;
    },
    async removeImage(uuid) {
      this.isLoading = true;
      this.showSuccess = false;
      this.showFailure = false;
      //1. remove uuid from CDN
      let resp = await UploadcareService.deleteImage(uuid);

      //2. see if the response has an error
      if(!("details" in resp)) {
        //3. since there were no errors, remove uuid from the user's data
        this.userData.OtherImagesUUID = this.userData.OtherImagesUUID.replace(uuid + ",", "")
        
        //4. update the user
        this.userData["IsActive"] = 1;
        let resp = await NopService.UpdateCustomer(this.userData);
        if (resp.Status === 1) {
          this.showFailureToDelete = false;
          this.userData = resp.Data;
        }
        else {
          this.showFailureToDelete = true;
        }

        //5. refresh file list
        await this.getNopImages();
      }
      else {
        this.showFailureToDelete = true;
      }
      this.isLoading = false;
    },
    async getNopImages() {
      this.uploadedMediaFiles = [];
      let uuidList = this.userData.OtherImagesUUID.split(",");
      for (var uuid of uuidList) {
        if (uuid !== ''){
          let img = await UploadcareService.getImageData(uuid);
          if (img) {
            this.uploadedMediaFiles.push(img)
          }
        }
      }
    },
    getUploadcareImage(uuid){
      return UploadcareService.buildImageUrl(uuid, "-/resize/x300/")
    },
  },
};
</script>
